import { TeamSession } from '@index/interfaces';
import { Timestamp } from 'firebase/firestore';

import { GthModel } from './model';

export class GthTeamSessionModel extends GthModel<TeamSession> {
  get id() {
    return this.model.id;
  }

  get label() {
    return this.model.label;
  }

  get status() {
    return this.model.status;
  }

  get startDate() {
    return this.handleFirestoreDate(this.model.startDate);
  }

  get completedDate() {
    return this.handleFirestoreDate(this.model.completedDate);
  }

  get archived() {
    return this.model.archived;
  }

  get archivedDate() {
    return this.handleFirestoreDate(this.model.archivedDate);
  }

  constructor(id: string, model: TeamSession) {
    super(id, model);
  }

  handleFirestoreDate(date?: Date | Timestamp) {
    if (!date) return;
    if (date instanceof Date) {
      return date;
    } else {
      return date.toDate();
    }
  }
}
