import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TeamSession, TeamSessionStatus } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthTeamSessionModel } from '@sentinels/models';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamSessionService {
  afs = inject(AngularFirestore);

  baseRef(teamId: string) {
    return this.afs.collection(DBUtil.Team).doc(teamId).collection(DBUtil.TeamSessions);
  }

  create(teamId: string, value: Partial<TeamSession>) {
    const id = this.afs.createId();
    return from(
      this.baseRef(teamId)
        .doc(id)
        .set(Object.assign({}, { id }, value)),
    );
  }

  update(teamId: string, id: string, dataToUpdate: { [key: string]: any }) {
    return from(this.baseRef(teamId).doc(id).update(dataToUpdate));
  }

  delete(teamId: string, id: string) {
    return from(this.baseRef(teamId).doc(id).delete());
  }

  getAll(teamId: string) {
    return this.baseRef(teamId)
      .get()
      .pipe(
        map((docs) => {
          if (docs.empty) return [];
          return docs.docs.map(
            (doc) => new GthTeamSessionModel(doc.id, doc.data() as TeamSession),
          );
        }),
      );
  }

  getActiveSession(teamId: string) {
    return this.afs
      .collection(DBUtil.Team)
      .doc(teamId)
      .collection(DBUtil.TeamSessions, (ref) =>
        ref.where('status', '==', TeamSessionStatus.ACTIVE),
      )
      .get()
      .pipe(
        map((docs) => {
          if (docs.empty) return;
          // there "SHOULD" only be one active session.
          const docArray = docs.docs.map(
            (doc) => new GthTeamSessionModel(doc.id, doc.data() as TeamSession),
          );
          return docArray[0];
        }),
      );
  }
}
